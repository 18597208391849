import { useRef, useEffect, useState } from "react"
import "./App.css"
// import DragToAim from './DragToAim.js';

/////////////// to do //////////////
/* 
- start pulling on click
- fix ball posn on hit
- shot clock
- camera flashes
- missing sounds
- icons not words
- flight takes longer
*/

// console.log("08:37 30dec");
///////////////////////// consts /////////////////////////////

const TARGET_HIT_ZONE = {
	// in vh and vw
	// using bottom, so that up is a 'positive y axis'
	// bottom: 28.6,
	bottom: 25,
	height: 7,
	left: 0,
	width: 11.8,
}
const HOW_MANY_TRIES = 3
const DIFFICULTY = 1 // 2 is easy, 1 is harder
const EASY = 1.5 // DIFFICULTY
const TRICKY = 1 // DIFFICULTY
const CRUEL = 0.6 // DIFFICULTY
const INITIAL_DISTANCE = 1
const POWER = 2.9
/* SETTING POWER
	 - Vertically ball travels ( dy(pulled from start point) * power )
	 - Start point is random distance down from game origin  // 50vh - ( random from 0 to 20)vh
	 -  hoop is TARGET_HIT_ZONE.bottom above centre // 28.6vh
	 so need *just enough* power to get from screen bottom (50vh below centre) to hoop (28.6vh above), when ball starts at lowest point (eg 20vh below centre) - in the example 'just enough' power would be 
	 ( total distance ) / ( pulled distance )
	 ( 50 + 28.6 ) / (50 - 20 )
	 ( 78.6 ) / ( 30 )
	 = 2.62, or 2.7 to be safe
*/

function App() {
	///////////////////////// refs and state /////////////////////////////
	const [ballEndPoint, setBallEndPoint] = useState({ x: 0, y: 0 })
	const currentBallStartPositionRef = useRef({
		x: null,
		y: null,
		scale: null,
	})
	const [ballPosition, setBallPosition] = useState(currentBallStartPositionRef.current) // see reset
	const [isFinished, setIsFinished] = useState(false)
	const endOfQuizButtonData_ref = useRef({
		canPlayAgain: true,
		showResultsUrl: "",
		studentPlannerUrl: "",
	})
	/* const [endOfQuizButtonData, setEndOfQuizButtonData] = useState({...endOfQuizButtonData_ref.current
	}) */
	const [targetText, setTargetText] = useState("")
	const gameClassRef = useRef("phase_beforeQuizStart") // phase_waitingForNextCorrectQuizAnswer phase_readyToShoot phase_hasHit phase_hasMissed
	const [gameClass, setGameClass] = useState(gameClassRef.current)
	const fall = useRef(0)
	const basketStreak = useRef(0)
	const difficulty_ref = useRef(EASY)
	// const showDifficultyAsModal_ref = useRef(false)
	const [showDifficultyAsModal, setshowDifficultyAsModal] = useState(1)
	const [difficulty, setDifficulty] = useState(EASY) // 2 is easy., 1 is harder
	const phase_readyToShootRef = useRef(false)
	const hitOrMissRef = useRef("")
	const tooHighOrLow_ref = useRef(null)

	/////////////// onMount: reset and postMessage ////////////////
	const messageListenerIsAddedRef = useRef(false)

	const [basketsScored, setBasketsScored] = useState(0)

	useEffect(() => {
		phase_beforeQuizStart()
		get_iFrameUrl_from_parent_url()
		// prevent prob with double render in react 18
		if (messageListenerIsAddedRef.current !== true) {
			messageListenerIsAddedRef.current = true
			resetForNewShot() // initialise - includes resetForNewShot()
			window.addEventListener("message", e => onPostMessageFromiFrame(e))
		}

		// return window.removeEventListener("message", callback); // <-  unused cleanup
	}, [])

	const onPostMessageFromiFrame = e => {
		// if (phase_readyToShootRef.current !== true) return

		// console.log("onPostMessageFromiFrame", e.data);
		// console.log("eventType?", e.data.eventType);
		// console.log("correct?", e.data.correct);

		// user clicks 'start' in quiz geturlqu
		if (e.data.eventType === "STARTED") {
			console.log("quiz has started")
			update_progressFlag("STARTED")
			phase_waitingForNextCorrectQuizAnswer()
		}

		if (e.data.eventType === "FINISHED") {
			console.log("FINISHED", e.data.eventType)
			// console.log("canPlayAgain", e.data.canPlayAgain);
			// console.log("showResultsUrl", e.data.showResultsUrl);
			// console.log("studentPlannerUrl", e.data.studentPlannerUrl);
			phase_endOfGame()
			update_progressFlag("FINISHED")
			setIsFinished(true)
			endOfQuizButtonData_ref.current = {
				canPlayAgain: e.data.eventType,
				showResultsUrl: e.data.showResultsUrl,
				studentPlannerUrl: e.data.studentPlannerUrl,
			}
			return
		}
		if (e.data.eventType !== "ANSWERED") {
			// console.log("not ANSWERED", e.data.eventType);
		}
		if (e.data.eventType == "NEXT") {
			window.scrollTo(0, 0)
		}
		// on correct answer
		if (e.data.eventType === "ANSWERED" && e.data.correct === true) {
			// console.log("correct answer")
			onCorrectQuizAnswer()
		}
	}

	function getURLQueryParameterByName(name, url = window.location.href) {
		name = name.replace(/[\[\]]/g, "\\$&")
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url)
		if (!results) return null
		if (!results[2]) return ""
		return decodeURIComponent(results[2].replace(/\+/g, " "))
	}

	const iFrameUrlRef = useRef()
	const [iFrameUrl, setIframeUrl] = useState(iFrameUrlRef)

	const urlHasToken = useRef(false)
	function get_iFrameUrl_from_parent_url() {
		let iFrameUrl // default for demo page ir no quiz assigned
		const quizUuid = extractUUID(window.location.href)
		// console.log("quizUuid", quizUuid);
		const token = getURLQueryParameterByName("token")
		// console.log("token", token);
		const quizMode = getURLQueryParameterByName("quizMode")
		// console.log("quizMode", quizMode);
		const origin = window.location.origin
		// console.log("origin", origin);
		const params = []

		// overrides default when quiz is assigned
		if (token) {
			urlHasToken.current = true
			params.push("token=" + token)
		}
		if (origin) {
			params.push("origin=" + origin)
		}
		if (quizMode) {
			params.push("quizMode=" + quizMode)
		}
		// console.log("params", params);

		// actual code for creating url
		if (window.location.host.includes("quizalize")) {
			iFrameUrl = `https://player.quizalize.com/quiz/${quizUuid}`
		}
		if (window.location.host.includes("test-")) {
			iFrameUrl = `https://test-player.quizalize.com/quiz/${quizUuid}`
		}
		if (params.length > 0) {
			iFrameUrl = iFrameUrl + "?" + params.join("&")
		}

		// on hoopzz prod url, without assigning a quiz
		// so, browser url is https://test-hoopzz-solo.quizalize.com/
		if (!quizUuid && !token) {
			iFrameUrl =
				"https://player.quizalize.com/quiz/a20a8c25-6a51-42ea-9543-303a96ffb0ac?origin=https://hoopzz-solo.quizalize.com"
			// console.log("!quizUuid && !token");
		}
		// on test url, without assigning a quiz
		if (!quizUuid && !token && window.location.host.includes("test-")) {
			iFrameUrl =
				// "https://test-player.quizalize.com/quiz/a20a8c25-6a51-42ea-9543-303a96ffb0ac?origin=https://test-hoopzz-solo.quizalize.com"
				"https://test-player.quizalize.com/quiz/57c54abf-3860-443d-aef3-c762b0f25308?origin=https://test-hoopzz-solo.quizalize.com&quizMode=smart_questions"
			// console.log(
			// 	"!quizUuid && !token && window.location.host.includes('test-' "
			// );
		}
		// on localhost
		if (window.location.host.includes("localhost")) {
			iFrameUrl =
				// "https://test-player.quizalize.com/quiz/57c54abf-3860-443d-aef3-c762b0f25308?origin=https://test-hoopzz-solo.quizalize.com&quizMode=smart_questions"
				"https://test-player.quizalize.com/quiz/57c54abf-3860-443d-aef3-c762b0f25308?origin=https://test-hoopzz-solo.quizalize.com&quizMode=smart_review"
			// console.log("window.location.host.includes('localhost' ");
		}
		// if (window.location.host.includes("localhost")) {
		// 	iFrameUrl =
		// 		"https://test-player.quizalize.com/quiz/a20a8c25-6a51-42ea-9543-303a96ffb0ac?origin=https://test-hoopzz-solo.quizalize.com"
		// 	// console.log("window.location.host.includes('localhost' ");
		// }

		updateIframeUrl(iFrameUrl)
	}

	function extractUUID(string) {
		const matches = string.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
		if (matches) return matches[0]
		else return null
	}

	function updateIframeUrl(newUrl) {
		// console.log("updateIframeUrl(iFrameUrl);", iFrameUrl);
		iFrameUrlRef.current = newUrl
		setIframeUrl(iFrameUrlRef.current)
	}

	///////////////////////// game phases /////////////////////////////

	function phase_beforeQuizStart() {
		console.log("%cphase_beforeQuizStart", "color: #00aa00")
		updateWords("", "Start the quiz", "Each correct answer gets more tries")
		updateGameClass("phase_waitingForNextCorrectQuizAnswer")
		setTargetText("")
	}

	// called by event listener when iFrame messages a correct answer
	function onCorrectQuizAnswer() {
		// console.log("onCorrectQuizAnswer()")
		currentBallStartPositionRef.current = randomise_ballStartPosition()
		updateShotsRemaining(shotsRemaining + HOW_MANY_TRIES)
		// updateShotsRemaining(HOW_MANY_TRIES)
		resetForNewShot()
		phase_readyToShoot()
		// console.log("onCorrectQuizAnswer()");
	}

	function phase_gettingBallReady() {
		setGameClass("phase_gettingBallReady")
		setTimeout(() => phase_readyToShoot(), 600)
	}

	function phase_readyToShoot() {
		phase_readyToShootRef.current = true
		console.log("%cphase_readyToShoot()", "color: #00aa00")
		// console.log("pointsForShot_ref.current", pointsForShot_ref.current)
		// console.log("shots left", shotsRemaining);
		updateWords(
			"",
			`Next shot wins ${pointsForShot_ref.current}${
				pointsForShot_ref.current > 1 ? "\u00A0points" : "\u00A0point"
			}`,
			`pull the ball to shoot`
		)
		setGameClass("phase_readyToShoot")
		setTargetText("")
		updateElasticLine({ ...elasticLineRef.current, mode: null }) ////
	}
	function updateWords_in_phase_readyToShoot_because_difficulty_has_been_changed() {
		console.log("updateWords_in_phase_readyToShoot_because_difficulty_has_been_changed()")
		updateWords(
			"",
			`Next shot wins ${pointsForShot_ref.current}${
				pointsForShot_ref.current > 1 ? "\u00A0points" : "\u00A0point"
			}`,
			`pull the ball to shoot`
		)
	}

	function phase_duringShot() {
		phase_readyToShootRef.current = false
		console.log("%cphase_duringShot()", "color: #00aa00")
		updateWords("", "", "")
		updateGameClass("phase_duringShot")
		setTargetText("")
		// console.log("ballPosition phase_duringShot", ballPosition);
		// console.log("ballEndPoint phase_duringShot", ballEndPoint);
	}

	function phase_hasHit() {
		console.log("%cphase_hasHit", "color: #00aa00")
		updateWords("Good shot", `+${pointsForShot_ref.current} ${pointsForShot_ref.current > 0 ? "points" : "point"}`)
		updateGameClass("phase_hasHit")
		setBasketsScored(basketsScored + pointsForShot_ref.current)

		// if i take the out, the ball doesn't fall, but the position bug SEEMS to go away
		setBallPosition({ x: 0, y: -20, scale: 1 })
		currentBallStartPositionRef.current = randomise_ballStartPosition()
		playSound("basketballCrowdCheer", 400)
		playSound("basketballScore", 0)
		setTimeout(() => resetForNewShot(), 1200)

		// upadte ready for coninued streak
		basketStreak.current += 1
	}

	function phase_hasMissed() {
		console.log("%cphase_hasMissed", "color: #00aa00")
		console.log("tooHighOrLow_ref.current", tooHighOrLow_ref.current)
		console.log("tooHighOrLow_ref.current == TooLow", tooHighOrLow_ref.current == "TooLow")
		// console.log("ballPosition phase_hasMissed", ballPosition);
		// console.log("ballEndPoint phase_hasMissed", ballEndPoint);
		updateShotsRemaining(shotsRemainingRef.current - 1)
		if (shotsRemaining > 1)
			updateWords("OOPS", `${shotsRemaining - 1} ${shotsRemaining > 2 ? "balls" : "ball"} left`, "Keep trying")
		if (shotsRemaining > 1 && tooHighOrLow_ref.current == "tooHigh") {
			updateWords(
				"TOO HIGH!",
				`${shotsRemaining - 1} ${shotsRemaining > 2 ? "balls" : "ball"} left`,
				"Try less power"
			)
		}
		if (shotsRemaining > 1 && tooHighOrLow_ref.current == "tooLow") {
			updateWords(
				"AIM HIGHER",
				`${shotsRemaining - 1} ${shotsRemaining > 2 ? "balls" : "ball"} left`,
				"Pull a bit harder"
			)
		}
		updateGameClass("phase_hasMissed")
		playSound("basketballMiss", 0)
		// playSound("basketballCrowdSad", 100);
		// playSound("basketballMiss", 1400);
		setTimeout(() => resetForNewShot(), 200)
	}

	function resetForNewShot() {
		// var quizframe = document.getElementById('quizframe');
		// console.log("document.body", document.body.scroll);
		// window.scrollTo(0,0);
		// document.body.scrollTo(0,0);
		setShotPower(0)

		// console.log("distanceScale", distanceScale);
		// console.log("basketStreak", basketStreak.current);
		// console.log(
		// 	"distanceScale from basketStreak",
		// 	Math.pow(0.9, basketStreak.current)
		// );
		if (hitOrMissRef.current === "missTarget") {
		}
		if (hitOrMissRef.current === "hitsTarget") {
			setDistanceScale(Math.pow(0.9, basketStreak.current))
			console.log("setDistanceScale", Math.pow(0.9, basketStreak.current))
		}
		if (shotsRemainingRef.current > 0) {
			setBallPosition(currentBallStartPositionRef.current)
			phase_gettingBallReady()
			return
		}
		if (shotsRemainingRef.current < 1) {
			phase_waitingForNextCorrectQuizAnswer()
			return
		}
		// if neither...
		console.log("resetForNewShot()- neither case was triggered")
	}

	function phase_waitingForNextCorrectQuizAnswer() {
		console.log("%cphase_waitingForNextCorrectQuizAnswer", "color: #00aa00")
		updateWords("", "Answer the quiz to continue", "")
		updateGameClass("phase_waitingForNextCorrectQuizAnswer")
		setTargetText("")
		setDistanceScale(1)
		basketStreak.current = 0
		playSound("basketballMiss", 0)
	}

	function phase_endOfGame() {
		console.log("%cphase_endOfGame", "color: #00aa00")
		updateWords("GAME OVER", "", "")
		setTargetText("")
		// see also: isFinished
	}

	///////////// functions ////////////////////

	function randomise_ballStartPosition() {
		let randomX = -15 + Math.floor(Math.random() * 30) // -15 to +15
		let randomY = -20 + Math.floor(Math.random() * 30) // -20 up to +10
		return {
			x: randomX,
			y: randomY,
			scale: "1",
		}
	}

	const soundOnOff_ref = useRef(true)

	const playSound = (soundId, timeout = 1500) => {
		// console.log("try to play sound", soundId)
		const sound = document.getElementById(soundId)

		setTimeout(() => {
			if (soundOnOff_ref.current && sound) {
				sound.play()
			}
		}, timeout)
	}

	function tryToShoot(e) {
		if (shotsRemaining) shoot(e)
	}

	// shoot is called on mouseUp or DragEnd
	function shoot(e) {
		if (phase_readyToShootRef.current !== true) return
		//////// calc trajectory (end point) /////////
		const mouseToBall = get_mouseUp_to_ballStartPoint(e, ballPosition) // x (vw) and y (vh)
		const g = 1 // fake gravity
		const power = POWER // ball (ghost) end point is placed this multiple of the length of the elastic line away...
		//... then gravity is added

		// ball end point with gravity (fall)
		fall.current = mouseToBall.y * g
		const ballEndPointAfterFlight = {
			x: power * mouseToBall.x + ballPosition.x,
			y: power * mouseToBall.y + ballPosition.y - fall.current,
		}
		setBallEndPoint({
			x: ballEndPointAfterFlight.x,
			y: ballEndPointAfterFlight.y,
		})

		//////// HIT or MISS ? /////////

		// console.log("difficulty", difficulty);

		// this calc compensates for the css transform: -50%
		const leftEdge = (targetZone.left - targetZone.width / 2) * difficulty_ref.current
		const rightEdge = (targetZone.left - targetZone.width / 2 + targetZone.width) * difficulty_ref.current
		const bottomEdge = targetZone.bottom
		const topEdge = targetZone.bottom + targetZone.height * difficulty_ref.current
		const hitsTarget_x =
			leftEdge < ballEndPointAfterFlight.x && ballEndPointAfterFlight.x < rightEdge ? true : false
		const hitsTarget_y =
			bottomEdge < ballEndPointAfterFlight.y && ballEndPointAfterFlight.y < topEdge ? true : false
		const hitsTarget = hitsTarget_x && hitsTarget_y ? "hitsTarget" : "missTarget"
		// console.log("hitsTarget?", hitsTarget);
		updateHitOrMissClass(hitsTarget)
		///////////////////////// w i p ////////////////////////

		tooHighOrLow_ref.current = null // reset
		if (ballEndPointAfterFlight.y > topEdge) tooHighOrLow_ref.current = "tooHigh"
		if (ballEndPointAfterFlight.y < bottomEdge) tooHighOrLow_ref.current = "tooLow"

		///////////////////////////////////////////

		//////// MOVE THE BALL to its end point (0.6s) /////////

		phase_duringShot()
		setBallPosition({
			x: ballEndPointAfterFlight.x,
			y: ballEndPointAfterFlight.y,
			scale: `${0.4 * distanceScale * difficulty_ref.current}`,
		})
		setTimeout(() => goToHitOrMissPhase(), 600)
	}

	//////////////////// use or remove ///////////////////////
	function checkWillHitOrMiss(e) {
		console.log("phase_readyToShootRef.current", phase_readyToShootRef.current)
		const mouseToBall = get_mouseUp_to_ballStartPoint(e, ballPosition) // x (vw) and y (vh)
		const g = 1 // fake gravity
		const power = POWER // ball (ghost) end point is placed this multiple of the length of the elastic line away...
		//... then gravity is added

		// ball end point with gravity (fall)
		fall.current = mouseToBall.y * g
		const ballEndPointAfterFlight = {
			x: power * mouseToBall.x + ballPosition.x,
			y: power * mouseToBall.y + ballPosition.y - fall.current,
		}
		// setBallEndPoint({
		// 	x: ballEndPointAfterFlight.x,
		// 	y: ballEndPointAfterFlight.y,
		// })

		//////// HIT or MISS ? /////////

		// console.log("difficulty", difficulty);

		// this calc compensates for the css transform: -50%
		const leftEdge = (targetZone.left - targetZone.width / 2) * difficulty_ref.current
		const rightEdge = (targetZone.left - targetZone.width / 2 + targetZone.width) * difficulty_ref.current
		const bottomEdge = targetZone.bottom
		const topEdge = targetZone.bottom + targetZone.height * difficulty_ref.current
		const hitsTarget_x =
			leftEdge < ballEndPointAfterFlight.x && ballEndPointAfterFlight.x < rightEdge ? true : false
		const hitsTarget_y =
			bottomEdge < ballEndPointAfterFlight.y && ballEndPointAfterFlight.y < topEdge ? true : false
		const hitsTarget = hitsTarget_x && hitsTarget_y ? "hitsTarget" : "missTarget"
		// console.log("hitsTarget?", hitsTarget);
		// updateHitOrMissClass(hitsTarget)

		console.log("checkWillHitOrMiss(e)", hitsTarget === "hitsTarget" ? true : false)
		return hitsTarget ? true : false
	}

	function updateHitOrMissClass(hitsTarget) {
		hitOrMissRef.current = hitsTarget
	}
	function goToHitOrMissPhase() {
		if (hitOrMissRef.current === "hitsTarget") {
			phase_hasHit()
		}
		if (hitOrMissRef.current === "missTarget") {
			phase_hasMissed()
		}
		// setTimeout(() => resetForNewShot(), 1200);
	}

	let pointsForShot_ref = useRef(10)
	let [pointsForShot, setPointsForRef] = useState(pointsForShot_ref.current)
	function updatePointsForRef() {
		// console.log("updatePointsForRef()")
		pointsForShot_ref.current = calc_points_for_next_shot(basketStreak.current, difficulty_ref.current)
		// pointsForShot_ref.current = newPoints
		setPointsForRef(pointsForShot_ref.current)
	}

	function calc_points_for_next_shot(streak = 1, difficulty = 1) {
		// console.log("calc_points_for_next_shot", streak, difficulty_ref.current)
		let points = streak > 0 ? streak : 1 // which will be the streak if the next shot is scored
		if (difficulty_ref.current === EASY) {
			points *= 1
		}
		if (difficulty_ref.current === TRICKY) {
			points *= 10
		}
		if (difficulty_ref.current === CRUEL) {
			points *= 100
		}
		return points
	}

	function updateDifficulty(newDifficulty) {
		difficulty_ref.current = newDifficulty
		setDifficulty(newDifficulty)
		setshowDifficultyAsModal(false)
	}

	/////////////////////// elastic line ///////////////////////////

	const elasticLineRef = useRef({
		on: false,
		x: null,
		y: null,
		height: null,
		width: null,
	})
	const [elasticLine, setElasticLine] = useState({ ...elasticLineRef.current })
	function updateElasticLine(newValue) {
		elasticLineRef.current = newValue
		setElasticLine(elasticLineRef.current)
	}

	function handleShowElasticLine() {
		elasticLineRef.current = { ...elasticLineRef.current, on: true }
		setElasticLine({ ...elasticLineRef.current })
	}

	const [shotPower, setShotPower] = useState(0)
	function calcElasticLine(e, isTouch) {
		const mouseToBall = get_mouseUp_to_ballStartPoint(e, ballPosition, isTouch) // x (vw) and y (vh)
		const newShotPower = Math.floor(mouseToBall.y * 2)
		// console.log("newShotPower", newShotPower)
		setShotPower(newShotPower)
		// don't bother if the mouse is waving around above the ball
		if (mouseToBall.y <= 0) {
			elasticLineRef.current = {}
			setElasticLine(elasticLineRef.current)
			return
		}

		let newElasticLine = {}
		if (mouseToBall.x > 0.1) {
			newElasticLine = {
				mode: "upToTheRight",
				bottom: ballPosition.y - mouseToBall.y,
				left: ballPosition.x - mouseToBall.x,
				height: mouseToBall.y,
				width: mouseToBall.x,
			}
		}
		if (mouseToBall.x < 0.1) {
			newElasticLine = {
				mode: "upToTheLeft",
				bottom: ballPosition.y - mouseToBall.y,
				left: ballPosition.x,
				height: mouseToBall.y,
				width: -mouseToBall.x,
			}
		}
		// fix width glitch
		if (-0.1 <= mouseToBall.x && mouseToBall.x <= 0) {
			newElasticLine = { ...newElasticLine, width: -0.13 }
		}
		if (0 <= mouseToBall.x && mouseToBall.x <= 0.1) {
			newElasticLine = { ...newElasticLine, width: 0.13 }
		}

		updateElasticLine(newElasticLine)
	}
	/////////////////////// elastic line ///////////////////////////

	const [distanceScale, setDistanceScale] = useState(calcDistanceScale())
	function calcDistanceScale(distance = INITIAL_DISTANCE) {
		return 1 / distance
	}

	///////////// scale the target zone ////////////////////

	const [targetZone, setTargetZone] = useState({ ...TARGET_HIT_ZONE })

	useEffect(() => {
		// console.log("useEffect")
		updatePointsForRef()
		// updatePointsForRef(calc_points_for_next_shot(basketStreak.current+1, difficulty))

		// console.log("useEffect TARGET_HIT_ZONE.bottom * distanceScale * difficulty", TARGET_HIT_ZONE.bottom, distanceScale, difficulty, '=', TARGET_HIT_ZONE.bottom * distanceScale * difficulty);
		setTargetZone({
			bottom: (TARGET_HIT_ZONE.bottom / difficulty) * distanceScale,
			// bottom: TARGET_HIT_ZONE.bottom * distanceScale * difficulty,
			height: TARGET_HIT_ZONE.height * distanceScale * difficulty_ref.current,
			left: TARGET_HIT_ZONE.left * distanceScale * difficulty_ref.current,
			width: TARGET_HIT_ZONE.width * distanceScale * difficulty_ref.current,
		})
	}, [distanceScale, difficulty_ref.current, basketStreak.current])

	/////////////////////// three shots ///////////////////////////
	const shotsRemainingRef = useRef(0)
	const [shotsRemaining, setShotsRemaining] = useState(shotsRemainingRef.current)
	function updateShotsRemaining(num) {
		shotsRemainingRef.current = num
		setShotsRemaining(num)
		console.log("shotsRemainingRef.current", shotsRemainingRef.current)
	}
	const getShotsRemaining = () => {
		// const shotsRemainingArray = new Array(3)
		const shotsRemainingArray = Array(shotsRemaining).fill("shot")
		return shotsRemainingArray.map(shot => (
			<>
				<div
					className="Ball"
					key={shot.index}
				>
					<div className="ballHighlight"></div>
					<div className="ballLine1"></div>
					<div className="ballLine2"></div>
					<div className="ballLine3"></div>
					<div className="ballGlow"></div>
				</div>
			</>
		))
	}

	///////////////////////// words /////////////////////////////

	const wordsRef = useRef({
		bigWords: "",
		smallWordsLine1: "",
		smallWordsLine2: "",
	})

	const [words, setWords] = useState({ ...wordsRef.current })

	function updateWords(bigWords = "", smallWordsLine1 = "", smallWordsLine2 = "") {
		wordsRef.current = { bigWords, smallWordsLine1, smallWordsLine2 }
		setWords({ ...wordsRef.current })
	}

	function updateGameClass(whichClass) {
		gameClassRef.current = whichClass
		setGameClass(whichClass)
	}

	/////////// progressFlag for Google Tag Manager //////////
	const progressFlagRef = useRef("")
	const [progressFlag, setprogressFlag] = useState(progressFlagRef.current)

	// function calc_progress_though_quiz() {}

	function update_progressFlag(string) {
		progressFlagRef.current = string
		setprogressFlag(progressFlagRef.current)
	}
	/////////// testLog //////////
	const [testLog, setTestLog] = useState("testLog")

	///////////////////////// HTML /////////////////////////////

	return (
		<div className={gameClass}>
			<main
				onMouseUp={e => tryToShoot(e)}
				onDragEnd={e => tryToShoot(e)}
				onMouseMove={e => {
					calcElasticLine(e)
					console.log("move")
					console.log("difficulty_ref.current", difficulty_ref.current)
					if (difficulty_ref.current == EASY) {
						console.log("check")
						checkWillHitOrMiss(e)
					}
				}}
				onTouchStart={e => {
					setTestLog("onTouchStart")
				}}
				/* onTouchMove={e => {
					// calcElasticLine(e.touches[0], "touch");
					setTestLog("onTouchMove");
				}} */
				/* onTouchEnd={e => {
					e.preventDefault();
					e.stopPropagation();
					tryToShoot(e.changedTouches[0]);
					setTestLog("onTouchEnd");
				}} */
			>
				<div className="main-inner">
					<div className="gameOrigin">
						<div className="court">
							<div
								className="court-group-for-scaling"
								style={{ scale: `${distanceScale * difficulty_ref.current}` }}
							>
								<img
									className="court-floor-fixed"
									src="/images/hoopzz-fullscreen-BG-fixed-image.jpg"
									alt=""
								/>
								<img
									className="court-lines"
									src="/images/hoopzz-fullscreen-BG-court-lines.svg"
									alt=""
								/>
								<div className="lights">
									<div className="spot spot1">
										<div className="innerSpot1"></div>
										<div className="innerSpot2"></div>
										<div className="innerSpot3"></div>
									</div>
									<div className="spot spot2">
										<div className="innerSpot1"></div>
										<div className="innerSpot2"></div>
										<div className="innerSpot3"></div>
									</div>
									<div className="spot spot3">
										<div className="innerSpot1"></div>
										<div className="innerSpot2"></div>
										<div className="innerSpot3"></div>
									</div>
									<div className="spot spot4">
										<div className="innerSpot1"></div>
										<div className="innerSpot2"></div>
										<div className="innerSpot3"></div>
									</div>
								</div>
								<div
									className="barriers"
									style={{
										backgroundImage: `url(/images/logoForBarrier_LOGO.svg)`,
									}}
								></div>
								<div
									className="ballShadow"
									style={{ scale: ballPosition.scale }}
								></div>
								<div
									className="hoop dev"
									style={
										{
											// translate: `-50% ${-50 / difficulty}%`,
										}
									}
								>
									<img
										className="hoop-back dev"
										src="/images/hoop-back.webp"
										alt=""
										style={{
											translate: `-50% 19%`,
											bottom: `${
												TARGET_HIT_ZONE.bottom /
												(difficulty_ref.current * difficulty_ref.current)
											}vh`,
											transition: `bottom .3s`,
										}}
									/>
									<img
										className="hoop-front"
										src="/images/hoop-front.webp"
										alt=""
										style={{
											translate: `-50% 90%`,
											bottom: `${
												TARGET_HIT_ZONE.bottom /
												(difficulty_ref.current * difficulty_ref.current)
											}vh`,
											transition: `bottom .3s`,
										}}
									/>
								</div>

								<div className="words">
									<div className="bigWords">
										{basketStreak.current > 1 ? (
											<>
												<div className="basketStreak">{basketStreak.current}</div>
												<div className="basketStreak">streak</div>
											</>
										) : (
											words.bigWords
										)}
										{/* {words.bigWords} */}
									</div>
									<div className="smallWords">{words.smallWordsLine1}</div>
									{/* <div className="smallWords">{words.smallWordsLine2}</div> */}
									<div className="shotPower">{shotPower > 0 && `Power: ${shotPower}`}</div>
								</div>
							</div>

							<div
								id="ballHolder"
								style={{
									bottom: ballPosition.y + "vh",
									left: ballPosition.x + "vw",
								}}
							>
								<div
									className="Ball realBall"
									style={{
										scale: ballPosition.scale,
										opacity: elasticLine.mode && gameClass === "phase_readyToShoot" ? 0 : 1,
									}}
									onTouchStart={e => {
										setTestLog("onTouchStart")
									}}
									onTouchMove={e => {
										calcElasticLine(e.touches[0], "touch")
										// console.log("e", e);
									}}
									onTouchEnd={e => {
										e.preventDefault()
										e.stopPropagation()
										tryToShoot(e.changedTouches[0])
										setTestLog("onTouchEnd")
									}}
								>
									<div className="ballHighlight"></div>
									<div className="ballLine1"></div>
									<div className="ballLine2"></div>
									<div className="ballLine3"></div>
									<div className="ballGlow"></div>
									<div className="pullMe_text">Pull me!</div>
								</div>
							</div>

							<div
								id="target"
								className="dev2"
								style={{
									// bottom: targetZone.bottom + "vh",
									bottom: targetZone.bottom + "vh",
									height: targetZone.height + "vh",
									left: targetZone.left + "vw",
									width: targetZone.width + "vh",
								}}
							>
								<div className="targetText">{targetText}</div>
							</div>

							<div
								className="hoop hoop-front-holder"
								style={{ scale: `${distanceScale * difficulty_ref.current}` }}
							>
								<img
									className="hoop-back"
									src="/images/hoop-back.webp"
									alt=""
									style={{ opacity: "0.05" }}
								/>
								<img
									className="hoop-front"
									style={{
										translate: `-50% 90%`,
										bottom: `${
											TARGET_HIT_ZONE.bottom / (difficulty_ref.current * difficulty_ref.current)
										}vh`,
										transition: `bottom .3s`,
									}}
									src="/images/hoop-front.webp"
									alt=""
								/>
								{/* NB this is a hidden duplicate, shown when a hit is scored, so that the ball can pass 'inside' it */}
							</div>
						</div>

						<div className="scoreboard">
							<div className="shotsRemaining">{getShotsRemaining()}</div>
							<div className="basketsScored">{basketsScored} </div>
							<div className="points">points</div>
						</div>

						{elasticLine.mode ? (
							<div
								className="elasticLine"
								style={{
									width: elasticLine.width + "vw",
									height: elasticLine.height + "vh",
									left: elasticLine.left + "vw",
									bottom: elasticLine.bottom + "vh",
								}}
							>
								{elasticLine.mode === "upToTheRight" ? (
									<div className="upToTheRight">
										<svg
											height={elasticLine.height + "vh"}
											width="100%"
										>
											<defs>
												<marker
													id="arrowhead"
													markerWidth="5"
													markerHeight="4"
													refX="0"
													refY="2"
													orient="auto-start-reverse"
													style={{
														fill: "rgba(255,255,0, 0.8)",
													}}
												>
													<polygon points="0 0, 5 2, 0 4" />
												</marker>
											</defs>
											<line
												strokeDasharray="2,12"
												markerStart="url(#arrowhead)"
												x1="100%"
												y1="0"
												x2="0"
												y2="100%"
												style={{
													stroke: "rgba(255,255,0, 0.8)",
													strokeWidth: 9,
													strokeLinecap: "round",
												}}
											/>
											Sorry, your browser does not support inline SVG.
										</svg>
										<div
											className="Ball pulledBall"
											style={{
												// scale: getPulledBallScale,
												scale: `${ballPosition.scale - 0 + shotPower / 30}`,
												transitionDuration: `${0.1}s`,
											}}
										>
											<div className="ballHighlight"></div>
											<div className="ballLine1"></div>
											<div className="ballLine2"></div>
											<div className="ballLine3"></div>
											<div className="ballGlow"></div>
										</div>
									</div>
								) : null}
								{elasticLine.mode === "upToTheLeft" ? (
									<div className="upToTheLeft">
										<svg
											height={elasticLine.height + "vh"}
											width="100%"
										>
											<defs>
												<marker
													id="arrowhead"
													markerWidth="5"
													markerHeight="4"
													refX="0"
													refY="2"
													orient="auto-start-reverse"
													style={{
														fill: "rgba(255,255,0, 0.8)",
													}}
												>
													<polygon points="0 0, 5 2, 0 4" />
												</marker>
											</defs>
											<line
												strokeDasharray="2,12"
												markerStart="url(#arrowhead)"
												x1="0"
												y1="0"
												x2="100%"
												y2="100%"
												style={{
													stroke: "rgba(255,255,0, 0.8)",
													strokeWidth: 9,
													strokeLinecap: "round",
												}}
											/>
											Sorry, your browser does not support inline SVG.
										</svg>
										<div
											className="Ball pulledBall"
											style={{
												// scale: getPulledBallScale,
												scale: `${ballPosition.scale - 0 + shotPower / 30}`,
												transitionDuration: `${0.1}s`,
											}}
										>
											<div className="ballHighlight"></div>
											<div className="ballLine1"></div>
											<div className="ballLine2"></div>
											<div className="ballLine3"></div>
											<div className="ballGlow"></div>
										</div>
									</div>
								) : null}
							</div>
						) : null}

						{difficulty_ref.current >= 1 ? (
							<div
								className="ballEndPoint"
								style={{
									left: ballEndPoint.x + "vw",
									bottom: ballEndPoint.y + "vh",
								}}
							></div>
						) : null}
					</div>
				</div>
			</main>

			<div className={`difficulty-select ${showDifficultyAsModal ? "showDifficultyAsModal" : ""}`}>
				{showDifficultyAsModal ? (
					<>
						<div
							className="logo"
							style={{
								backgroundImage: `url(/images/hoopzz-solo-LOGO.svg)`,
							}}
						></div>
						<p className="small">choose difficulty</p>{" "}
					</>
				) : null}

				<div
					className={`difficulty-option ${difficulty_ref.current !== EASY ? "" : "selected"}`}
					onClick={() => {
						updateDifficulty(EASY)
						updatePointsForRef()
						updateWords_in_phase_readyToShoot_because_difficulty_has_been_changed()
						console.log("difficulty-option updateDifficulty(EASY)", EASY)
					}}
				>
					<h4>EASY</h4>
					<div className="comment">but fewer points</div>
				</div>
				<div
					className={`difficulty-option ${difficulty_ref.current !== TRICKY ? "" : "selected"}`}
					onClick={() => {
						updateDifficulty(TRICKY)
						updatePointsForRef()
						updateWords_in_phase_readyToShoot_because_difficulty_has_been_changed()
						console.log("difficulty-option updateDifficulty(TRICKY)", TRICKY)
					}}
				>
					<h4>TRICKY</h4>
				</div>
				<div
					className={`difficulty-option ${difficulty_ref.current !== CRUEL ? "" : "selected"}`}
					onClick={() => {
						updateDifficulty(CRUEL)
						updatePointsForRef()
						updateWords_in_phase_readyToShoot_because_difficulty_has_been_changed()
						console.log("difficulty-option updateDifficulty(CRUEL)", CRUEL)
					}}
				>
					<h4>CRUEL</h4>
					<div className="comment">super hi scoring</div>
				</div>
			</div>

			{isFinished ? (
				<div className="endOfQuizOptions">
					<h2>Nice work!</h2>

					{endOfQuizButtonData_ref.current.showResultsUrl ? (
						<div className="endOfQuizButton checkYourResults pulsing">
							<a href={endOfQuizButtonData_ref.current.showResultsUrl}>Now, go here</a>
						</div>
					) : endOfQuizButtonData_ref.current.canPlayAgain ? (
						<div
							className="endOfQuizButton playAgain pulsing"
							onClick={() => window.location.reload(false)}
						>
							Start the quiz again?
						</div>
					) : null}

					{/* <h2>Well done</h2>
					{endOfQuizButtonData_ref.current.canPlayAgain ? (
						<div
							className="endOfQuizButton playAgain"
							onClick={() => window.location.reload(false)}
						>
							Start the quiz again?
						</div>
					) : null}
					{endOfQuizButtonData_ref.current.showResultsUrl ? (
						<div className="endOfQuizButton checkYourResults">
							<a href={endOfQuizButtonData_ref.current.showResultsUrl}>Check your results</a>
						</div>
					) : null}
					{endOfQuizButtonData_ref.current.studentPlannerUrl ? (
						<div className="endOfQuizButton studentPlannerUrl">
							<a href={endOfQuizButtonData_ref.current.studentPlannerUrl}>Save and finish</a>
						</div>
					) : null} */}
				</div>
			) : (
				<div className="quizplayer">
					<iframe
						id="quizframe"
						src={iFrameUrl}
						height="100%"
						width="100%"
						title="quizalize quiz player"
						frameBorder="0"
					></iframe>

					<div className="quizplayerOverlayMask">
						<div className="maskA"></div>
						<div className="maskB"></div>
						<div className="maskC"></div>
						<div className="maskTextA">Hello world</div>
					</div>
				</div>
			)}

			{/* <div className="mobileOverlay">
				<h2>Hello</h2>
				<p>This game doesn't work well on mobile just yet</p>
				<p>
					{" "}
					<span>(but it might tomorrow!)</span>{" "}
				</p>
				<p>
					{" "}
					<span>
						We've started this way because many of the classrooms that use
						Quizalize seem to have Chromebooks, but we're working right now on
						making this great on mobiles and tablets too - we're almost there!
					</span>{" "}
				</p>
			</div> */}
			<div className="testButtons">
				{/* <button onClick={() => setDistanceScale(distanceScale * 1.25)}>
					+
				</button>
				<button onClick={() => setDistanceScale(distanceScale / 1.25)}>
					-
				</button> */}
				{/* <button
					onClick={() => {
						setDifficulty(1.5);
						console.log("testbutton setDifficulty(1.5)");
					}}
				>
					easy
				</button>
				<button
					onClick={() => {
						setDifficulty(1);
						console.log("testbutton setDifficulty(1)");
					}}
				>
					hard
				</button> */}
			</div>
			{progressFlag && (
				<div className="progressFlags">
					{progressFlag === "STARTED" && (
						<>
							<div
								className="progressFlag"
								id="player_progress_started"
							>
								started
							</div>
							<div
								className="progressFlag"
								id="a_player_started"
							>
								started
							</div>
							{urlHasToken.current === true && (
								<div
									className="progressFlag"
									id="player_progress_started_assigned"
								>
									started (assigned)
								</div>
							)}
						</>
					)}

					{progressFlag === "FINISHED" && (
						<>
							<div
								className="progressFlag "
								id="player_progress_game_over"
							>
								finished
							</div>
							<div
								className="progressFlag "
								id="a_player_finished"
							>
								finished
							</div>
							{urlHasToken.current === true && (
								<div
									className="progressFlag"
									id="player_progress_game_over_assigned"
								>
									finished (assigned)
								</div>
							)}
						</>
					)}
				</div>
			)}

			<div className="sounds">
				<audio id="basketballScore">
					<source
						// src="/sounds/basketball_miss.mp3"
						src="/sounds/basketball_score_mar23.mp3"
						type="audio/mpeg"
					/>
					Your browser does not support the audio tag.
				</audio>
				<audio id="basketballMiss">
					<source
						src="/sounds/basketball_miss.mp3"
						type="audio/mpeg"
					/>
					Your browser does not support the audio tag.
				</audio>
				<audio id="basketballCrowdCheer">
					<source
						src="/sounds/basketball_crowd_cheer.mp3"
						type="audio/mpeg"
					/>
					Your browser does not support the audio tag.
				</audio>
				{/* <audio id="basketballCrowdSad" autoplay="false" autostart="0" autostart="false">
					<source src="/sounds/basketball_crowd_sad.mp3" type="audio/mpeg"  />
					Your browser does not support the audio tag.
				</audio> */}
				{/* <audio id="basketballCrowdBoo">
        <source 
          src="/assets/sounds/basketball_crowd_boo.mp3"
          type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
      <audio loop id="crowdAmbientSound">
        <source 
          src="/assets/sounds/basketball_crowd_ambient_comp.mp3"
          type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
      <audio loop id="themeSong">
				<source
          src="/assets/sounds/basketball_theme_song_loop.mp3"
          type="audio/mpeg"/>        
        Your browser does not support the audio tag.
      </audio> */}
			</div>

			{testLog && <div className="testLog">{testLog}</div>}
		</div>
	)
}

///////////////////////// helpers /////////////////////////////

function get_mouseUp_to_ballStartPoint(e, ballPosition) {
	// on default (desktop) viewport, game fills the right hald of the screen
	// let gameOrigin_x = 76.5; // fudge
	let gameOrigin_x = 26.5 // fudge

	// but on mobile viewport, game is centred
	if (
		document.documentElement.clientWidth < 800 &&
		document.documentElement.clientWidth < document.documentElement.clientHeight
	) {
		gameOrigin_x = 50
	}

	const relativeMouseXInVw = (e.clientX / document.documentElement.clientWidth) * 100 - gameOrigin_x // assumes ball at horizontal middle of game, see above
	const relativeMouseYInVh = ((e.clientY / document.documentElement.clientHeight) * 100 - 50) * -1 // assumes ball at top: 50vh

	const mouseToBall_x = ballPosition.x - relativeMouseXInVw
	const mouseToBall_y = ballPosition.y - relativeMouseYInVh

	return { x: mouseToBall_x, y: mouseToBall_y }
}

///////////////////////// end /////////////////////////////

export default App
